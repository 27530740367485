import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";

// images
import userPlaceholder from "images/user-placeholder.png";

import Header from "components/headers/light.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
// import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import ContactUsForm from "components/forms/SimpleContactUs.js";
import Footer from "components/footers/SimpleFiveColumn.js";

export default () => {
  const HighlightedText = tw.span`text-primary-500`;
  return (
    <AnimationRevealPage>
      <Header />
      <Hero />
      <MainFeature />
      <div id="service">
        <Features
          heading={
            <>
              Amazing <HighlightedText>Service</HighlightedText>
            </>
          }
        />
      </div>
      {/* <MainFeature2 /> */}
      <div id="work">
        <Portfolio />
      </div>
      <Testimonial
        subheading="Testimonials"
        heading={
          <>
            Our Clients <span tw="text-primary-500">Love Us.</span>
          </>
        }
        description="Here are what some of our amazing customers are saying about our service."
        testimonials={[
          {
            imageSrc:
              "https://images.unsplash.com/photo-1576678927484-cc907957088c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
            profileImageSrc: userPlaceholder,
            quote:
              "We thank Codistree for the wonderful job in helping us develop our Gym member managment system. Everyone was professional, excellent and hard working. Thanks to them, we were able to achieve our goal on time, and we look forward to continue working with them in the future.",
            customerName: "Maruti Sharma",
            customerTitle: "CEO, MFZ Gym",
          },
          {
            imageSrc:
              "https://images.unsplash.com/photo-1573495628363-04667cedc587?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
            profileImageSrc: userPlaceholder,
            quote:
              "I would say I liked working with Codistree, They was quick to understand the job at hand, and they quickly understood the existing codebase. If I ever need any more work in full stack web development, I would definitely work with them again",
            customerName: "Rahul Kumar",
            customerTitle: "Upwork Client",
          },
          {
            imageSrc:
              "https://images.unsplash.com/photo-1541870132-e3a73a19426a?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
            profileImageSrc: userPlaceholder,
            quote:
              "Codistree team does great work and is very thorough!",
            customerName: "Jordan Arsenault",
            customerTitle: "Upwork Client",
          },
        ]}
        textOnLeft={true}
      />
      <div id="contact">
        <ContactUsForm />
      </div>
      <Footer />
    </AnimationRevealPage>
  );
};
