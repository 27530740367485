import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyBHGHOHv8e0fpMTshbkJRMp9w5rhXwDBKg",
    authDomain: "codistree-94fc7.firebaseapp.com",
    projectId: "codistree-94fc7",
    storageBucket: "codistree-94fc7.appspot.com",
    messagingSenderId: "897876436468",
    appId: "1:897876436468:web:ad0e799b55e98792f285a1",
    measurementId: "G-PKVHBNYDEK"
  };

firebase.initializeApp(firebaseConfig);

export default firebase;